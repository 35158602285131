import React, { useEffect, useState } from "react";
import axios from "axios";

function RideRequestList() {
  const [rideRequests, setRideRequests] = useState([]);
  const [users, setUsers] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    if (!token) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}ride-requests/`)
      .then((response) => {
        const rideRequests = response.data;
        setRideRequests(rideRequests);
        return rideRequests;
      })
      .then((rideRequests) => {
        rideRequests.forEach((request) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}users/${request.passenger}`)
            .then((response) => {
              setUsers((prevUsers) => ({
                ...prevUsers,
                [request.passenger]: response.data,
              }));
            })
            .catch((error) => {
              console.error("There was an error fetching the user!", error);
            });
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the ride requests!", error);
      });
  }, []);

  if (!isLoggedIn) {
    return (
      <div className="flex justify-center text-error">
        You must be logged in to view ride offers.
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="md:w-1/2 w-full">
        <h1 className="text-center text-2xl font-bold text-neutral py-4">
          Available Ride Requests{" "}
        </h1>
        {rideRequests.length > 0 ? (
          rideRequests.map((offer) => (
            <div
              className="collapse border border-accent text-neutral mb-4 hover:bg-neutral-100"
              key={offer.id}
            >
              <input type="checkbox" />
              <div className="collapse-title text-l font-medium pl-20 ">
                <span className="text-2xl">{offer.origin}</span> to{" "}
                <span className="text-2xl">{offer.destination}</span> at{" "}
                {new Date(offer.departure_time).toLocaleString()}
              </div>
              <div className="collapse-content bg-white">
                <div className="join pt-5">
                  <div className="avatar placeholder pr-10 pl-10 pt-5 pb-5">
                    <div className="w-24 rounded-full">
                      <img src={"/default_profile.jpg"} alt="profile-pic" />
                    </div>
                  </div>
                  <div>
                    <p>
                      Ride requested by{" "}
                      <b>
                        {users[offer.passenger]?.first_name || "Unknown"}{" "}
                        {users[offer.passenger]?.last_name || "Unknown"}
                      </b>
                    </p>
                    <p>
                      Seats requested:{" "}
                      {offer.seats_requested || "Not specified"}
                    </p>
                    <p>Request Details: {offer.description || "Unknown"}</p>
                    <p>
                      Phone Number:{" "}
                      {users[offer.passenger]?.phone_number || "Unknown"}
                    </p>
                    <p>
                      Date Posted:{" "}
                      {new Date(offer.request_time).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">
            No ride requests available at the moment.
          </p>
        )}
      </div>
    </div>
  );
}

export default RideRequestList;
