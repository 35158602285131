import React, { useState } from "react";
import axios from "axios";
import zxcvbn from "zxcvbn";

const UserRegistration = () => {
  const [isDriverChecked, setIsDriverChecked] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    is_driver: false,
    car_model: "",
    car_color: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "email") {
      const username = value.split("@")[0];
      newFormData = { ...newFormData, username };
    }

    if (name === "is_driver") {
      setIsDriverChecked(checked);
    }

    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const passwordStrength = zxcvbn(formData.password);
      if (passwordStrength.score < 3) {
        setError("Error: Your password is too weak.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}users/`,
        formData
      );
      console.log("Registration successful:", response.data);
      setSuccess("Registration successful");
      setError(null);
      // window.location.href = "/login";
    } catch (error) {
      console.error("Registration error:", error);
      // Handle registration error
      const errors = Object.values(error.response?.data || {}).join(" ");
      setError(`Error: ${errors}`);
      setSuccess(null);
    }
  };

  const fields = [
    { name: "first_name", type: "text", label: "First Name" },
    { name: "last_name", type: "text", label: "Last Name" },
    { name: "email", type: "email", label: "Email" },
    { name: "password", type: "password", label: "Password" },
    { name: "is_driver", type: "checkbox", label: "Is Driver" },
    { name: "car_model", type: "text", label: "Car Model" },
    { name: "car_color", type: "text", label: "Car Color" },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {fields.map((field) => {
          if (field.type === "checkbox") {
            return (
              <div className="flex items-center gap-2 mb-1" key={field.name}>
                <label className="cursor-pointer label flex gap-1">
                  <span className="label-text">Are you a driver?</span>
                  <input
                    type={field.type}
                    name={field.name}
                    defaultChecked={formData[field.name]}
                    onChange={handleChange}
                    className="checkbox checkbox-accent"
                  />
                </label>
              </div>
            );
          } else {
            return (
              <label
                className="input input-bordered flex items-center gap-2 mb-1"
                key={field.name}
              >
                {field.label}:
                <input
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="grow"
                  placeholder={field.label}
                  disabled={
                    (field.name === "car_model" ||
                      field.name === "car_color") &&
                    !isDriverChecked
                  }
                />
              </label>
            );
          }
        })}
        {error && <div className="text-error">{error}</div>}
        {success && <div className="text-success">{success}</div>}
        <button className="btn btn-accent w-full" type="submit">
          Sign up
        </button>
      </form>
    </div>
  );
};

export default UserRegistration;
