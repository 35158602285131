import React, { useEffect, useState } from "react";
import axios from "axios";

function RideOfferList() {
  const [rideOffers, setRideOffers] = useState([]);
  const [drivers, setDrivers] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    if (!token) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}ride-offers/`)
      .then((response) => {
        const now = new Date();
        const sortedOffers = response.data
          .filter((offer) => new Date(offer.departure_time) > now)
          .sort(
            (a, b) => new Date(a.departure_time) - new Date(b.departure_time)
          );
        setRideOffers(sortedOffers);
        return sortedOffers;
      })
      .then((offers) => {
        offers.forEach((offer) => {
          axios
            .get(`${process.env.REACT_APP_API_URL}users/${offer.driver}`)
            .then((response) => {
              setDrivers((prevDrivers) => ({
                ...prevDrivers,
                [offer.driver]: response.data,
              }));
            })
            .catch((error) => {
              console.error("There was an error fetching the driver!", error);
            });
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the ride offers!", error);
      });
  }, []);

  if (!isLoggedIn) {
    return (
      <div className="flex justify-center text-error">
        You must be logged in to view ride offers.
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="md:w-1/2 w-full">
        <h1 className="text-center text-2xl font-bold text-neutral py-4">
          Available Ride Offers
        </h1>
        {rideOffers.length > 0 ? (
          rideOffers.map((offer) => (
            <div
              className="collapse border border-accent text-neutral mb-4 hover:bg-neutral-100"
              key={offer.id}
            >
              <input type="checkbox" />
              <div className="collapse-title text-l font-medium pl-20 ">
                <span className="text-2xl">{offer.origin}</span> to{" "}
                <span className="text-2xl">{offer.destination}</span> at{" "}
                {new Date(offer.departure_time).toLocaleString()}
              </div>
              <div className="collapse-content bg-white">
                <div className="join pt-5">
                  <div className="avatar flex pr-10 pl-10 pt-5 pb-5">
                    <div className="w-24 rounded-full">
                      <img src={"/default_profile.jpg"} alt="Profile" />
                    </div>
                  </div>
                  <div>
                    <p>
                      Ride offered by{" "}
                      <b>
                        {drivers[offer.driver]?.first_name || "Unknown"}{" "}
                        {drivers[offer.driver]?.last_name || "Unknown"}
                      </b>
                    </p>
                    <p>Seats offered: {offer.available_seats}</p>
                    <p>Fare: ${offer.ride_fare}</p>
                    <p>
                      Car Model: {drivers[offer.driver]?.car_model || "Unknown"}
                    </p>
                    <p>
                      Car Color: {drivers[offer.driver]?.car_color || "Unknown"}
                    </p>
                    <p>
                      Phone Number:{" "}
                      {drivers[offer.driver]?.phone_number || "Unknown"}
                    </p>
                    <p>
                      Date Posted:{" "}
                      {new Date(offer.request_time).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No ride offers available at the moment.</p>
        )}
      </div>
    </div>
  );
}

export default RideOfferList;
