import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function MainPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  return (
    <div className="hero bg-base pt-20">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Hello there</h1>
          <p className="py-6">
            WillyRide is a platform build for students to make transportation in
            Williamsburg area easier. Going somewhere? Request a ride and find
            your driver. Driving somewhere? Offer a ride and share gas expenses.
            Easy as that, enjoy!
          </p>
          {isLoggedIn ? (
            <>
              <Link to="/request-ride">
                <button className="btn btn-accent m-2">Find a Ride</button>
              </Link>

              <Link to="/offer-ride">
                <button className="btn btn-accent m-2">Offer a Ride</button>
              </Link>
            </>
          ) : (
            <Link to="/login">
              <button className="btn btn-accent m-2 w-1/2">Log in</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainPage;
