import React, { useState, useEffect } from "react";
import axios from "axios";

function RideOfferForm() {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [availableSeats, setAvailableSeats] = useState("");
  const [rideFare, setRideFare] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const loginErrorMessage = "You must be logged in to offer a ride";

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError(loginErrorMessage);
      return;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      setError(loginErrorMessage);
      return;
    }

    const userId = localStorage.getItem("userId");

    // Fetch user data
    const userResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}users/${userId}`,
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    const user = userResponse.data;
    if (!user.is_driver || !user.car_model || !user.car_color) {
      setError(
        "You must be a driver and have a car model and color set up to offer a ride"
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}ride-offers/`,
        {
          origin: origin,
          destination: destination,
          departure_time: departureTime,
          available_seats: parseInt(availableSeats, 10),
          driver: parseInt(userId, 10),
          ride_fare: parseFloat(rideFare),
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      console.log("Ride offer created successfully:", response.data);
      setSuccess("Ride offer created successfully!");
      setError(null);
      // Clear form fields
      setOrigin("");
      setDestination("");
      setDepartureTime("");
      setAvailableSeats("");
      setRideFare("");
    } catch (error) {
      setError("Failed to create ride offer");
      setSuccess(null);
    }
  };

  const formFields = [
    { label: "Origin", type: "text", value: origin, setter: setOrigin },
    {
      label: "Destination",
      type: "text",
      value: destination,
      setter: setDestination,
    },
    {
      label: "Departure Time",
      type: "datetime-local",
      value: departureTime,
      setter: setDepartureTime,
    },
    {
      label: "Available Seats",
      type: "number",
      value: availableSeats,
      setter: setAvailableSeats,
    },
    {
      label: "Ride Fare",
      type: "number",
      value: rideFare,
      setter: setRideFare,
    },
  ];

  if (error === loginErrorMessage) {
    return <div className="flex justify-center text-error">{error}</div>;
  }

  return (
    <div className="flex justify-center mb-4">
      <div className="w-3/10">
        <h1 className="text-center text-2xl font-bold text-neutral py-4">
          Offer a Ride
        </h1>
        <form onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <label
              className="input input-bordered flex items-center gap-2 mb-1"
              key={index}
            >
              {field.label === "Ride Fare" && (
                <span className="input-prefix">$</span>
              )}
              <input
                type={field.type}
                value={field.value}
                className="grow"
                placeholder={field.label}
                onChange={(e) => field.setter(e.target.value)}
                min={field.type === "number" ? 0 : undefined}
                required
              />
            </label>
          ))}
          {error && <div className="text-error">{error}</div>}
          {success && <div className="text-success">{success}</div>}
          <button className="btn btn-accent w-full" type="submit">
            Offer Ride
          </button>
        </form>
      </div>
    </div>
  );
}

export default RideOfferForm;
