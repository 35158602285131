import React, { useState, useEffect } from "react";
import axios from "axios";

function Profile() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isDriverChecked, setIsDriverChecked] = useState(false);
  const loginErrorMessage = "You must be logged in to view your profile";

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
      if (name === "is_driver") {
        setIsDriverChecked(checked);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError(loginErrorMessage);
      setLoading(false);
      return;
    } else {
      const fetchUserData = async () => {
        try {
          const userId = localStorage.getItem("userId");
          if (!userId) {
            throw new Error("User ID not found in local storage");
          }
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}users/${userId}`
          );
          setFormData(response.data); // Set form data to user data
          setLoading(false);
        } catch (error) {
          const errors = Object.values(error.response?.data || {}).join(" ");
          setError(`Error: ${errors}`);
          setLoading(false);
        }
      };
      fetchUserData();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    const token = localStorage.getItem("token");
    if (!token) {
      setError("You must be logged in to update your profile");
      return;
    }

    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError("User ID not found");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}users/${userId}/`,
        formData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      console.log("Profile updated successfully:", response.data);
      setSuccess("Profile updated successfully.");
    } catch (error) {
      const errors = Object.values(error.response?.data || {}).join(" ");
      setError(`Error: ${errors}`);
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  const formFields = [
    { label: "Username", name: "username", type: "text" },
    { label: "Email", name: "email", type: "email" },
    { label: "First Name", name: "first_name", type: "text" },
    { label: "Last Name", name: "last_name", type: "text" },
    { label: "Phone Number", name: "phone_number", type: "text" },
    { label: "Is Driver", name: "is_driver", type: "checkbox" },
    { label: "Car Model", name: "car_model", type: "text" },
    { label: "Car Color", name: "car_color", type: "text" },
  ];

  if (error === loginErrorMessage) {
    return <div className="flex justify-center text-error">{error}</div>;
  }

  return (
    <div className="flex justify-center mb-4">
      <div className="w-3/10">
        <h1 className="text-center text-3xl font-bold text-neutral py-4">
          Profile
        </h1>
        <div className="avatar flex justify-center items-center mb-4">
          <div className="w-24 rounded-full">
            <img src={"/default_profile.jpg"} alt="profile-pic" />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {formFields.map((field) => {
            if (field.type === "checkbox") {
              return (
                <div className="flex items-center gap-2 mb-1" key={field.name}>
                  <label className="cursor-pointer label flex gap-1">
                    <span className="label-text">Are you a driver?</span>
                    <input
                      type={field.type}
                      name={field.name}
                      defaultChecked={formData[field.name]}
                      onChange={handleInputChange}
                      className="checkbox checkbox-accent"
                    />
                  </label>
                </div>
              );
            } else {
              return (
                <label
                  className="input input-bordered flex items-center gap-2 mb-1"
                  key={field.name}
                >
                  {field.label}:
                  <input
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    className="grow"
                    placeholder={field.label}
                    disabled={
                      (field.name === "car_model" ||
                        field.name === "car_color") &&
                      !isDriverChecked
                    }
                  />
                </label>
              );
            }
          })}
          {error && <div className="text-error">{error}</div>}
          {success && <div className="text-success">{success}</div>}
          <button className="btn btn-accent w-full" type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default Profile;
