import React, { useState, useEffect } from "react";
import axios from "axios";

function RideRequestForm() {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [requestedSeats, setRequestedSeats] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const loginErrorMessage = "You must be logged in to request a ride";

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError(loginErrorMessage);
      return;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      setError(loginErrorMessage);
      return;
    }

    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}ride-requests/`,
        {
          origin: origin,
          destination: destination,
          departure_time: departureTime,
          seats_requested: parseInt(requestedSeats, 10),
          passenger: parseInt(userId, 10),
          description: description,
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      console.log("Ride request created successfully:", response.data);
      setSuccess("Ride request created successfully!");
      setError(null);
      // Clear form fields
      setOrigin("");
      setDestination("");
      setDepartureTime("");
      setRequestedSeats("");
      setDescription("");
    } catch (error) {
      setError("Failed to create ride request");
      setSuccess(null);
    }
  };

  const formFields = [
    { label: "Origin", type: "text", value: origin, setter: setOrigin },
    {
      label: "Destination",
      type: "text",
      value: destination,
      setter: setDestination,
    },
    {
      label: "Departure Time",
      type: "datetime-local",
      value: departureTime,
      setter: setDepartureTime,
    },
    {
      label: "Requested Seats",
      type: "number",
      value: requestedSeats,
      setter: setRequestedSeats,
    },
    {
      label: "Details / Fares / Other",
      type: "textarea",
      value: description,
      setter: setDescription,
    },
  ];

  if (error === loginErrorMessage) {
    return <div className="flex justify-center text-error">{error}</div>;
  }

  return (
    <div className="flex justify-center mb-4">
      <div className="w-3/10">
        <h1 className="text-center text-2xl font-bold text-neutral py-4">
          Request a Ride
        </h1>
        <form onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <label
              className="input input-bordered flex items-center gap-2 mb-1"
              key={index}
            >
              <input
                type={field.type}
                value={field.value}
                className={field.type === "textarea" ? "grow" : "grow"}
                placeholder={field.label}
                onChange={(e) => field.setter(e.target.value)}
                min={field.type === "number" ? 0 : undefined}
                required
              />
            </label>
          ))}
          {error && <div className="text-error">{error}</div>}
          {success && <div className="text-success">{success}</div>}
          <button className="btn btn-accent w-full" type="submit">
            Request Ride
          </button>
        </form>
      </div>
    </div>
  );
}

export default RideRequestForm;
