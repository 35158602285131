import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function Footer() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  return (
    <footer className="footer p-10 bg-base-200 text-base-content">
      <aside>
        <h3 className="text-4xl font-bold text-neutral-500">WillyRide</h3>{" "}
        <p>
          Share rides connecting with people and repairing your budget.
          <br />
          Copyright © 2024 - All rights reserved
        </p>
      </aside>
      <nav>
        <h6 className="footer-title">Links</h6>
        <a className="link link-hover">
          <Link to="/">Home</Link>
        </a>
        {isLoggedIn ? (
          <>
            <a className="link link-hover">
              <Link to="/ride-offers">Ride Offers</Link>
            </a>
            <a className="link link-hover">
              <Link to="/ride-requests">Ride Requests</Link>
            </a>
          </>
        ) : (
          <a className="link link-hover">
            <Link to="/login">Log in</Link>
          </a>
        )}
      </nav>
      <nav>
        <h6 className="footer-title">Legal</h6>
        <a className="link link-hover">
          <Link to="/tos">Terms of services</Link>
        </a>
        <a className="link link-hover">
          <Link to="/privacy-policy">Privacy policy</Link>
        </a>
      </nav>
    </footer>
  );
}

export default Footer;
