import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import RideOfferForm from "./components/RideOfferForm";
import RideOfferList from "./components/RideOfferList";
import RideRequestForm from "./components/RideRequestForm";
import RideRequestList from "./components/RideRequestList";
import Profile from "./components/Profile";
import LoginForm from "./components/LoginForm";
import Layout from "./components/Layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfServices from "./pages/TermsOfServices";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<MainPage />} />
            <Route path="offer-ride" element={<RideOfferForm />} />
            <Route path="ride-offers" element={<RideOfferList />} />
            <Route path="request-ride" element={<RideRequestForm />} />
            <Route path="ride-requests" element={<RideRequestList />} />
            <Route path="profile" element={<Profile />} />
            <Route path="login" element={<LoginForm />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="tos" element={<TermsOfServices />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
