import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Header() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      console.log(localStorage);
      axios
        .get(`${process.env.REACT_APP_API_URL}users/${userId}`)
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the user!", error);
        });
    } else {
      console.log(localStorage);
      console.log("No user logged in");
    }
  }, []);

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.href = "/login";
  }

  return (
    <header>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          <a className="btn btn-ghost text-xl">
            <Link to="/">WillyRide</Link>
          </a>
          <div className="badge badge-accent badge-outline">BETA</div>
        </div>
        <div className="md:flex hidden">
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link to="/">Home</Link>
            </li>
            {user ? (
              <>
                <li>
                  <Link to="/ride-offers">Ride Offers</Link>
                </li>
                <li>
                  <Link to="/ride-requests">Ride Requests</Link>
                </li>
              </>
            ) : (
              <li>
                <Link to="/login">Log in</Link>
              </li>
            )}
          </ul>
        </div>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle avatar"
          >
            <div className="w-10 rounded-full">
              <img alt="profile-pic" src="/default_profile.jpg" />
            </div>
          </div>
          {user ? (
            <ul
              tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
            >
              <>
                <li>Hello, {user.first_name}</li>
                <hr />
                <div className="md:hidden">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/ride-offers">Ride Offers</Link>
                  </li>
                  <li>
                    <Link to="/ride-requests">Ride Requests</Link>
                  </li>
                </div>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <button onClick={logout}>Logout</button>
                </li>
              </>
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
